import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import {
  scenarioRefinanceOption,
  scenarioTypes,
} from "../../../../commons/enum";
import DoughnutChartComponent from "../../../../components/doughnut-chart-component";
import { useIsScenarioOwner, useLvr } from "../../../../hooks/scenario.hooks";
import ScenarioFinanceInput from "./scenario-finance-input";

const ScenarioLoanToValueRatio = ({
  refinanceOption = "",
  setScenarioFilter,
  filterSelect = {},
  title = "",
}) => {
  const isOwner = useIsScenarioOwner();
  const { scenarioType = "", lvrSetting = {} } = filterSelect;
  const {
    estimateProperty = 0,
    cashTowardProperty = 0,
    monthlyRent = 0,
    estimateLoanRepayment = 0,
    outstandingLoanAmount = 0,
  } = lvrSetting;

  const lvr = useLvr(
    estimateProperty,
    refinanceOption === scenarioRefinanceOption.ADD_MANUALLY
      ? estimateProperty - outstandingLoanAmount
      : cashTowardProperty
  );

  const chartData = useMemo(() => {
    const scenarioDebt = estimateProperty - cashTowardProperty;
    const scenarioEquity = estimateProperty - scenarioDebt;
    return {
      data: [scenarioEquity, scenarioDebt > 0 ? scenarioDebt : 0],
      title: ["Equity", "Debt"],
    };
  }, [cashTowardProperty, estimateProperty]);

  const handleSliderValue = (value) => {
    setScenarioFilter({
      ...filterSelect,
      lvrSetting: { ...lvrSetting, ...value },
    });
  };
  useEffect(() => {
    setScenarioFilter({
      ...filterSelect,
      lvrSetting: { ...lvrSetting, lvr },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lvr]);

  return (
    <div className="scenario-filter-finance">
      <div className="scenario-filter-title">
        {scenarioType === scenarioTypes.REFINANCE_HOME_LOAN
          ? title
          : "Loan to Value Ratio Settings"}
      </div>
      <div className="scenario-lvr-setting">
        <div className="scenario-filter-item-container">
          <ScenarioFinanceInput
            title="Property Estimation"
            type="estimateProperty"
            handleChange={handleSliderValue}
            value={estimateProperty}
            isOwner={filterSelect.scenarioId && isOwner}
          />
          {scenarioType === scenarioTypes.REFINANCE_HOME_LOAN ? (
            <ScenarioFinanceInput
              title="Outstanding Loan Amount"
              type="outstandingLoanAmount"
              handleChange={handleSliderValue}
              value={outstandingLoanAmount}
              isOwner={filterSelect.scenarioId && isOwner}
            />
          ) : (
            <ScenarioFinanceInput
              title="Cash Towards Property"
              type="cashTowardProperty"
              handleChange={handleSliderValue}
              value={cashTowardProperty}
              isOwner={filterSelect.scenarioId && isOwner}
            />
          )}
          {refinanceOption === scenarioRefinanceOption.ADD_MANUALLY && (
            <ScenarioFinanceInput
              title="Loan Repayments"
              type="estimateLoanRepayment"
              handleChange={handleSliderValue}
              value={estimateLoanRepayment}
              isOwner={filterSelect.scenarioId && isOwner}
            />
          )}
          {scenarioType === scenarioTypes.BUY_AN_INVESTMENT && (
            <ScenarioFinanceInput
              title="Monthly Rent Estimation"
              type="monthlyRent"
              handleChange={handleSliderValue}
              value={monthlyRent}
              isOwner={filterSelect.scenarioId && isOwner}
            />
          )}
        </div>
        <div className="scenario-value-doughnutchart">
          <DoughnutChartComponent
            textTop={`${lvr}%`}
            textBottom="LVR"
            data={chartData}
          />
        </div>
      </div>
    </div>
  );
};

ScenarioLoanToValueRatio.propTypes = {
  filterSelect: PropTypes.shape({
    scenarioType: PropTypes.string,
    lvrSetting: PropTypes.shape({
      estimateProperty: PropTypes.number,
      cashDeposit: PropTypes.number,
      monthlyRent: PropTypes.number,
    }),
  }),
};

export default ScenarioLoanToValueRatio;
